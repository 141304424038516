export default {
  appTitle: '万知',
  appDesc: '你的AI工作平台',
  goTo: '前往万知',
  continueGoogle: '继续使用谷歌',
  continueApple: '继续使用苹果',
  continueEmail: '使用电子邮件注册',
  emailLogin: '电子邮件登录',
  emailAddress: '电子邮件地址',
  next: '下一步',
  descText: '继续即表示您同意',
  descService: '服务条款',
  descPrivacy: '隐私政策',
  verificationCode: '验证码',
  verify: '验证',
  back: '返回',
  deleteHistoryTitle: '你确定要删除此对话吗',
  deleteHistoryContent: '',
  delete: '删除',
  cancel: '取消',
  deleteHistorySuccess: '删除成功',
  deleteHistoryError: '删除失败',
  notChat: '暂时没有对话',
  loading: '加载中...',
  noMore: '没有更多了',
  me: '我的',
  historyChat: '历史对话',
  copyCode: '复制代码',
  copySuccess: '复制到您的系统剪贴板！',
  login: '登录',
  deleteSuccess: '删除成功',
  deleteFail: '删除失败',
};
