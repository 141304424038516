import { Skeleton } from 'antd-mobile';
import { Suspense } from 'react';

const fullScreenLoading = (
  <div
    style={{
      padding: 16,
    }}
  >
    <Skeleton.Title animated />
    <Skeleton.Paragraph lineCount={5} animated />
    <Skeleton.Paragraph lineCount={5} animated />
  </div>
);

function withSuspense<T extends JSX.IntrinsicAttributes>(
  WrappedComponent: React.FC<T>,
) {
  const LazySuspenseWrapper: React.FC<T> = (props) => (
    <Suspense fallback={fullScreenLoading}>
      <WrappedComponent {...props} />
    </Suspense>
  );

  return LazySuspenseWrapper;
}

export default withSuspense;
