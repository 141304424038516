export default {
  appTitle: 'PopAi',
  appDesc: 'Your productivity workstation',
  goTo: 'Go to PopAi',
  continueGoogle: 'Continue with Google',
  continueApple: 'Continue with Apple',
  continueEmail: 'Continue with email',
  emailLogin: 'Email Login',
  emailAddress: 'Email address',
  next: 'Next',
  descText: 'By continuing, you are agreeing to',
  descService: 'Terms of Service',
  descPrivacy: 'Privacy Policy',
  verificationCode: 'Verification code',
  verify: 'Verify',
  back: 'Back',
  deleteHistoryTitle: 'Delete Conversation?',
  deleteHistoryContent: '',
  delete: 'Delete',
  cancel: 'Cancel',
  deleteHistorySuccess: 'Successfully deleted',
  deleteHistoryError: 'Failed to delete',
  notChat: 'No historical response yet',
  loading: 'Loading...',
  noMore: 'No more',
  me: 'Me',
  historyChat: 'Chat History',
  copyCode: 'Copy Code',
  copySuccess: 'Copied to your system clipboard!',
  login: 'Login',
  deleteSuccess: 'Delete',
  deleteFail: 'failed',
};
