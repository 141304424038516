import { UserInfo } from '@/store';
import BrowserLogger from 'alife-logger';

const { VITE_APP_ALIFE_PID } = import.meta.env;

let __bl: any;

/**
 * 初始化监控
 */
export function initMonitor() {
  __bl = BrowserLogger.singleton({
    pid: VITE_APP_ALIFE_PID,
    appType: 'web',
    imgUrl: 'https://arms-retcode-sg.aliyuncs.com/r.png?',
    sendResource: true,
    enableLinkTrace: true,
    behavior: true,
    enableSPA: true,
    useFmp: true,
  });
}

/**
 * 更新监控配置
 * @param userinfo 用户信息
 */
export function updateMonitorConfig({ userinfo }: { userinfo: UserInfo }) {
  __bl?.setConfig({
    uid: userinfo.uid,
  });
}

/**
 * 上报自定义事件
 * @param error 错误信息
 */
export function monitorReportError(
  error: Error,
  pos: { filename?: string; lineno?: number; colno?: number } = {},
) {
  __bl?.error(error, pos);
}
