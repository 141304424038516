export default {
  feedback: 'Feedback',
  teamsOfUse: 'Teams of Use',
  privacyPolicy: 'Privacy Policy',
  rateUs: 'Rate us',
  logOut: 'Log Out',
  cancel: 'Cancel',
  modalTitle: 'Are you sure you want to log out?',
  usage: {
    manageBilling: 'Manage billing',
    enjoyInfinite: 'Enjoy the voyage into the infinite',
    per: 'Per',
    month: 'Month',
    day: 'Day',
    Usage: 'Usage',
    or: 'or',
    unlimitedPlan: 'Unlimited Plan',
    upgradeToPro: 'Upgrade to PRO',
  },
};
