export default {
  chatWithPdf: 'AI读文档',
  parsingFile: '解析中...',
  someQuestionsAsk: '你可能想问：',
  sendMessage: '请输入问题',
  pages: '页',
  regenerate: '重新生成大纲',
  generatePPT: '生成幻灯片',
  createPresentation: '生成幻灯片',
  errMessageTemplate:
    '哎呀！出事了。 您请求的引擎似乎不存在，或者处理您的请求时出现问题。',
  errChat: '当前网络请求不稳定，请稍后再尝试。',
  generationTime: '生成时间约1-2分钟，',
  viewLater: '可稍后查看或到万知桌面版继续AI编辑',
  preview: '预览',
};
