export default {
  hello: '你好',
  uploadTitle: 'AI读文档',
  uploadTipsTitle: 'AI Reader',
  uploadTipsDesc: '阅读论文，解析财务报表',
  uploadFiles: '上传文件',
  uploading: '上传中...',
  uploadFailed: '上传失败',
  cardContent: {
    cardTitle: 'AI创作',
    cardBoxTitle: 'AI一键标题生成演示文档',
    cardBoxTips: '在web桌面使用',
  },
  popTips: '由世界上最先进的语言模型提供支持，包括 ChatGPT',
  tryItOut: '你可以尝试',
  create: '新建',
  upload: '上传',
  chatWithDocument: 'AI读文档',
  uploadPDFAndLimit: '上传PDF文档，最高5000页',
  aiPresentation: 'AI创建PPT',
  enterTitleToGeneratePPT: '输入标题生成PPT',
  creating: '正在创建...',
  creatingFailed: '创建失败',
  supportFileType: '仅支持{{accept}}类型',
};
