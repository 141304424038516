export default {
  feedback:'التقيمات',
  teamsOfUse:'فرق الاستخدام',
  privacyPolicy:'سياسة الخصوصية',
  rateUs:'استخدام معدل',
  logOut:'تسجيل خروج',
  cancel:'كانكل',
  modalTitle:'هل أنت متأكد أنك تريد تسجيل الخروج من حسابك؟',
  usage: {
    manageBilling: 'إدارة الفواتير',
    enjoyInfinite: 'استمتع بالرحلة إلى اللانهائية',
    per: 'كل',
    month: 'شهر',
    day: 'يوم',
    Usage: 'الاستخدام',
    or: 'أو',
    unlimitedPlan: 'خطة غير محدودة',
    upgradeToPro: 'الترقية إلى PRO',
  },
}