import { MEMBERSHIP_ID } from '@/common/config';
import { updateMonitorConfig } from '@/common/monitor';
import { urlToBase64 } from '@/common/utils';
import { currentUserUsingGET } from '@/services';
import type { UserInfo } from '@/store';
import { useUserStore } from '@/store';
import { ResponseType } from '@/types';
import dayjs from 'dayjs';
import { useCallback } from 'react';

const useFetchUserInfo = () => {
  const { setUserInfo } = useUserStore();

  const fetchUserInfo = useCallback(async () => {
    try {
      const res = await currentUserUsingGET<ResponseType<UserInfo>>();
      const userInfoData = res.data;
      const avatar = await urlToBase64(userInfoData?.picture as string);
      const timeRemaining = userInfoData.membershipExpDate
        ? Math.ceil(
            dayjs(userInfoData.membershipExpDate).diff(dayjs(), 'day', true),
          )
        : null;

      const mergeUserinfo = {
        partnerStatus: '',
        partnerUid: '',
        refCode: '',
        ...userInfoData,
        isPro: [
          MEMBERSHIP_ID.MonthPro,
          MEMBERSHIP_ID.YearPro,
          MEMBERSHIP_ID.MonthProPlus,
          MEMBERSHIP_ID.YearProPlus,
          MEMBERSHIP_ID.MonthUnlimited,
          MEMBERSHIP_ID.YearUnlimited,
        ].includes(userInfoData.membershipId as number),
        isTrial: userInfoData.membershipId === MEMBERSHIP_ID.Trail,
        isProPlus: [
          MEMBERSHIP_ID.MonthProPlus,
          MEMBERSHIP_ID.YearProPlus,
          MEMBERSHIP_ID.MonthUnlimited,
          MEMBERSHIP_ID.YearUnlimited,
        ].includes(userInfoData.membershipId as number),
        isUnlimited: [
          MEMBERSHIP_ID.MonthUnlimited,
          MEMBERSHIP_ID.YearUnlimited,
        ].includes(userInfoData.membershipId as number),
        avatar: avatar as string,
        isLogin: true,
        timeRemaining,
      };

      setUserInfo(mergeUserinfo);
      updateMonitorConfig({ userinfo: mergeUserinfo });
    } catch (err: any) {
      console.log('err:', err);
    }
  }, []);

  return { fetchUserInfo };
};

export default useFetchUserInfo;
