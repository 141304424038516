export default {
  appTitle: 'PopAi',
  appDesc: 'محطة عمل قدرات الإنتاج الخاصة بك',
  continueGoogle: 'الاستمرار باستخدام Google',
  continueApple: 'الاستمرار باستخدام Apple',
  continueEmail: 'الاستمرار باستخدام البريد الإلكتروني',
  emailLogin: 'تسجيل الدخول بالبريد الإلكتروني',
  emailAddress: 'عنوان البريد الإلكتروني',
  next: 'التالي',
  descText: 'من خلال الاستمرار، أنت توافق على',
  descService: 'شروط الخدمة',
  descPrivacy: 'سياسة الخصوصية',
  verificationCode: 'رمز التحقق',
  verify: 'التحقق',
  back: 'العودة',
  deleteHistoryTitle: 'هل أنت متأكد من حذف هذه المحادثة？',
  deleteHistoryContent:
    'أنت تستخدم إصدارًا قديمًا من PopAi لنظام التشغيل iOS ولم يعد مدعومًا. قم بالتحديث للحصول على أحدث الميزات.',
  delete: 'يمسح',
  cancel: 'كانكل',
  deleteHistorySuccess: 'تم الحذف بنجاح',
  deleteHistoryError: 'فشل في الحذف',
  notChat: 'لا يوجد رد تاريخي حتى الآن',
  loading: 'تحميل...',
  noMore: 'لا أكثر',
  my: 'أنا',
  historyChat: 'الدردشة التاريخية',
  copyCode: 'نسخ الكود',
  copySuccess: 'تم النسخ إلى حافظة النظام الخاصة بك!',
  deleteSuccess: 'Delete',
  deleteFail: 'failed',
};
