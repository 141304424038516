import useFetchUserInfo from '@/hooks/useFetchUserInfo';
import useGASendEvent from '@/hooks/useGASendEvent';
import { useUserStore } from '@/store';
import { isBoolean } from 'lodash-es';
import { PropsWithChildren, useEffect } from 'react';

const Layout: React.FC<PropsWithChildren> = ({ children }) => {
  const { fetchUserInfo } = useFetchUserInfo();
  const { userInfo } = useUserStore();
  const { sendEvent } = useGASendEvent();
  useEffect(() => {
    if (!(isBoolean(userInfo?.isLogin) && userInfo?.isLogin)) return;
    fetchUserInfo();
  }, []);
  useEffect(() => {
    sendEvent('website_visit');
  }, []);
  return children;
};

export default Layout;
