/*
 * @Author: wubo
 * @Date: 2023-06-20 11:29:15
 * @LastEditTime: 2023-07-27 15:45:40
 * @LastEditors: wubo
 * @Descripttion:
 */
import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

const STORE_KEY = 'webapp-userinfo-storage';

export interface UserInfo {
  uid: string | null;
  email: string | null;
  username: string | null;
  picture: string | null;
  avatar: string; // base64转换后的头像
  isLogin: boolean | null;
  isPro: boolean;
  isProPlus: boolean;
  isUnlimited: boolean;
  isTrial: boolean;
  isNew: number | null;
  timeRemaining: number | null;
  membershipId: number | null;
  membershipExpDate: string | null; // 订阅过期时间
  stripeCustomer: string | null; // 是否stripe付费过
  fastspringCustomer: string | null; // 是否fastspring付费过
  createdAt: string | null;
  internal: number; // 1 内部员工
  partnerStatus?: string; //  是否是推广者  0 active、 1 pending 、2 blocked
  partnerUid?: string; // 推广者uid
  refCode?: string; // 推广码
  phoneNumber?: string; // 手机号
  parsedRoles?: string[];
}

export const defaultUserInfo: UserInfo = {
  uid: null,
  email: null,
  username: null,
  // google头像
  picture: null,
  // google头像转base64
  avatar: '',
  isLogin: null,
  isPro: false,
  isProPlus: false,
  isUnlimited: false,
  isTrial: false,
  isNew: null,
  timeRemaining: null,
  membershipId: null,
  membershipExpDate: null,
  stripeCustomer: null,
  fastspringCustomer: null,
  createdAt: null,
  internal: 0,
  partnerStatus: '',
  partnerUid: '',
  refCode: '',
};

interface UserState {
  userInfo: UserInfo;
  setUserInfo: (payload: Partial<UserInfo>) => void;
}

const useUserStore = create<
  UserState,
  [['zustand/devtools', never], ['zustand/persist', Partial<UserState>]]
>(
  devtools(
    persist(
      (set) => ({
        userInfo: defaultUserInfo,
        setUserInfo: (payload: Partial<UserInfo>) =>
          set(({ userInfo }) => {
            return { userInfo: { ...userInfo, ...payload } };
          }),
      }),
      {
        name: STORE_KEY,
        storage: createJSONStorage(() => localStorage),
        partialize: ({ userInfo }: UserState) => ({
          userInfo,
        }),
      },
    ),
  ),
);

export default useUserStore;
