import { useCallback } from 'react';
// import ReactGA from 'react-ga4';
import { getIpAndCountryUsingGET, ugActionUsingPOST } from '@/services';
import { useEventStore, useUserStore } from '@/store';
import { ResponseType } from '@/types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { debounce, isNil, omitBy } from 'lodash-es';
import { nanoid } from 'nanoid';
import qs from 'qs';

dayjs.extend(utc);
// interface EventTagMap {
//   [key: string]: string | number | undefined;
// }

interface IpAndCountryProp {
  ip: string;
  country: string;
}

const {
  //   VITE_APP_GA_ADS_ID_OLD,
  //   VITE_APP_GA_ADS_ID_NEW,
  VITE_APP_ANALYSIS_BASE_URL,
  //   VITE_APP_META_PIXEL_ID1,
  //   VITE_APP_META_PIXEL_ID2,
  //   VITE_APP_META_PIXEL_ID3,
  //   VITE_APP_BING_UET_TAG_ID,
  //   VITE_APP_TWITTER_PIXEL_ID,
  //   VITE_APP_TWITTER_PIXEL_ID1,
  //   VITE_APP_LINKEDIN_ID,
  VITE_APP_NAME,
  VITE_APP_ANALYSIS_ID,
  VITE_APP_AID,
  VITE_APP_PRODUCT_NAME,
  VITE_APP_APP_NAME,
} = import.meta.env;

const useGASendEvent = () => {
  const searchParams = qs.parse(location.search.replace('?', ''));
  const utm_source = searchParams?.['utm_source'];
  const utm_medium = searchParams?.['utm_medium'];
  const utm_campaign = searchParams?.['utm_campaign'];
  const utm_content = searchParams?.['utm_content'];
  const utm_term = searchParams?.['utm_term'];
  const utmInfo = {
    utm_source,
    utm_medium,
    utm_campaign,
    utm_content,
    utm_term,
  };

  const sendEvent = async (name: string, params?: any) => {
    const { userInfo } = useUserStore.getState();
    const {
      webId,
      sessionId,
      lastActivityTime,
      ip,
      country,
      setWebId,
      setSessionId,
      setLastActivityTime,
      setIp,
      setCountry,
    } = useEventStore.getState();

    let localWebId: string = webId;
    if (!localWebId) {
      const newWebId = nanoid();
      localWebId = newWebId;
      setWebId(newWebId);
    }
    let localSessionId: string = sessionId;
    const currentTime = Date.now();
    setSessionId((prev) => {
      const newSessionId = nanoid();
      if (!prev) {
        localSessionId = newSessionId;
        return newSessionId;
      } else {
        const isExpired =
          !lastActivityTime || currentTime - lastActivityTime > 30 * 60 * 1000;
        if (isExpired) {
          localSessionId = newSessionId;
          return newSessionId;
        } else {
          localSessionId = prev;
          return prev;
        }
      }
    });
    setLastActivityTime(currentTime);
    const eventParams = Object.assign({}, params, utmInfo, {
      referrer: document.referrer,
      eid: null,
      uid: userInfo?.uid,
    });
    const eventResult = omitBy(eventParams, isNil);
    // ReactGA.event(name, eventResult);

    // handleGoogleConversion(name);
    // handleMetaConversion(name, eventResult);
    // handleBingConversion(name);
    // handleTwitterConversion(name);
    // handleLinkedInConversion(name);

    const currentUTCTime = dayjs().utc().format('YYYY-MM-DD HH:mm:ss');

    // 接入大数据平台埋点 @晓东
    if (VITE_APP_ANALYSIS_ID) {
      let localIp: string = ip;
      let localCountry: string = country;
      if (!localIp || !localCountry) {
        try {
          const res = await getIpAndCountryUsingGET<ResponseType>();
          const data = res?.data as IpAndCountryProp;
          if (data) {
            setIp((prev) => {
              if (!prev) {
                localIp = data?.ip;
                return data?.ip;
              } else {
                return prev;
              }
            });
            setCountry((prev) => {
              if (!prev) {
                localCountry = data?.country;
                return data?.country;
              } else {
                return prev;
              }
            });
          }
        } catch (error) {
          console.error('error:', error);
        }
      }
      try {
        window.xaf.record({
          aid: VITE_APP_AID,
          recordId: VITE_APP_ANALYSIS_ID,
          recordData: {
            appid: VITE_APP_ANALYSIS_ID,
            utc_timestamp: currentUTCTime,
            product_name: VITE_APP_PRODUCT_NAME,
            app_name: VITE_APP_APP_NAME,
            device_info: {
              web_id: localWebId,
              baidu_id: localStorage.getItem('__bid_n'),
            },
            user_info: {
              uid: userInfo?.uid,
            },
            page_info: {
              url: location.href,
            },
            event_name: name,
            event_params: eventResult,
            ext: {
              country: localCountry,
              ip: localIp,
              session_id: localSessionId,
            },
          },
          timeout: 10000,
          complete: function () {},
        });
      } catch (e) {
        console.log(e);
      }
    }

    if (VITE_APP_ANALYSIS_BASE_URL) {
      ugActionUsingPOST({
        action_url: location.href,
        event_name: name,
        event_params: JSON.stringify(eventResult),
        user_agent: navigator.userAgent,
        os: 'pc',
        model: null,
        app_type: 'web',
        client_time: currentUTCTime,
        session_id: localSessionId,
        web_id: localWebId, // 浏览器指纹，短期内由nanoid替代， 原因是fingerprint普通版 准确性仅为60%，fingerprint
      });
    }

    if (VITE_APP_NAME !== 'production') {
      console.log(
        '%c 🍫 event',
        'color:#7f2b82',
        name,
        JSON.stringify(eventResult),
      );
    }
  };

  const sendEventDelay = useCallback(debounce(sendEvent, 300), []);

  return { sendEvent, sendEventDelay };
};

export default useGASendEvent;
