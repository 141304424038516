export default {
  hello: 'مرحبا',
  uploadTitle: 'تحميل الصورة',
  uploadTipsTitle: 'تحميل الصورة',
  uploadTipsDesc: 'يمكنك تحميل الصورة من الكمبيوتر أو الهاتف المحمول',
  uploadFiles: 'تحميل الصورة',
  uploading: 'تحميل...',
  uploadFailed: 'فشل التحميل',
  cardContent: {
    cardTitle: 'عرض تقديمي بواسطة الذكاء الاصطناعي',
    cardBoxTitle: 'قم بإنشاء شرائح في ثوانٍ من موضوع واحد',
    cardBoxTips: 'متوفر على سطح المكتب',
  },
  popTips: 'مدعوم من نموذج اللغة الأكثر تقدمًا في العالم، بما في ذلك ChatGPT',
  tryItOut: 'يمكنك تجربته',
  create: 'إنشاء',
};
