import axios from 'axios';
import { UAParser } from 'ua-parser-js';

export async function urlToBase64(url: string) {
  try {
    if (!url) {
      return null;
    }
    const response = await axios.get(url, {
      timeout: 5000,
      responseType: 'arraybuffer',
    });
    const blob = new Blob([response.data], { type: 'image/png' });
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
}

interface riskControlTokenProps {
  code: number;
  msg: string;
  jt: string;
}
/**
 * 增加风控
 * 通过大数据JS SDK 异步获取token，并将token放置到请求头header中
 */
export const getRiskControlToken = () => {
  return new Promise((resolve, reject) => {
    const data = {
      aid: import.meta.env.VITE_APP_AID,
      complete: function (data: riskControlTokenProps) {
        if (data.code === 0) {
          resolve(data?.jt);
        } else {
          reject(data?.jt);
          console.log('risk control error:', data);
        }
      },
    };
    window.xaf.report(data);
  });
};

export const calculateHash = (
  chunkList: {
    chunk: Blob;
  }[],
): Promise<string> => {
  return new Promise((resolve) => {
    const worker: Worker = new Worker('/hashWorker.js');
    worker.postMessage({ chunkList: chunkList });
    worker.onmessage = (e) => {
      const { hash } = e.data;
      if (hash) {
        resolve(hash);
      }
    };
  });
};

export const getFileExtension = (filename: string) => {
  const dotIndex = filename.lastIndexOf('.');
  if (dotIndex !== -1) {
    const extension = filename.substring(dotIndex + 1);
    return extension;
  }
  return '';
};

const CHUNK_SIZE = 1 * 1024 * 1024;

// 拆分文件
export const splitFile = (file: File, size = CHUNK_SIZE) => {
  const fileChunkList = [];
  let curChunkIndex = 0;
  while (curChunkIndex <= file.size) {
    const chunk = file.slice(curChunkIndex, curChunkIndex + size);
    fileChunkList.push({ chunk: chunk });
    curChunkIndex += size;
  }
  return fileChunkList;
};

export const blobToUint8Array = async (blob: Blob) => {
  try {
    if (blob instanceof Blob) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const res = new Uint8Array(reader.result as ArrayBuffer);
          resolve(res);
        };
        reader.readAsArrayBuffer(blob);
      });
    }
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
};

/**
 * 判断文案是否是阿拉伯语
 * @param text 目标文案
 */
export const isArabic = (text: string) => {
  let arabicWordCount = 0;
  let totalWordCount = 0;

  // 使用正则表达式匹配阿拉伯文单词
  const wordRegex = /[\u0600-\u06FF]+/gu;

  // 使用exec方法迭代匹配的单词
  while (wordRegex.exec(text) !== null) {
    totalWordCount++;
    arabicWordCount++;
  }

  // 判断阿拉伯文单词占比是否超过一半
  return arabicWordCount / totalWordCount > 0.5;
};

const parser = new UAParser(window?.navigator?.userAgent);
export const getDevice = () => {
  return parser.getDevice()?.type || 'desktop';
};
export const getBaseUrl = () => {
  return import.meta.env.VITE_APP_HOST === '/'
    ? ''
    : import.meta.env.VITE_APP_API_PROXY;
};
/**
 * 判断是否是移动设备
 * @returns 是否是移动设备
 */
export function isMobileDevice() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );
}

export const isDesktop = () => {
  return getDevice() === 'desktop';
};

export const copyText = (text: string) => {
  function copyWithDocumentCommand() {
    // 创建一个隐藏的input元素
    var input = document.createElement('input');
    input.setAttribute('type', 'text');
    input.setAttribute('value', text);
    document.body.appendChild(input);
    input.select();
    var result = document.execCommand('copy');
    document.body.removeChild(input);
    if (result) {
      console.log('链接复制成功');
    } else {
      console.error('链接复制失败');
    }
  }

  // 尝试使用Clipboard API进行复制操作
  if ('clipboard' in navigator) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log('链接复制成功');
      })
      .catch((err) => {
        console.error('使用Clipboard API失败:', err);
        // 如果Clipboard API失败，则尝试使用document.execCommand方法
        copyWithDocumentCommand();
      });
  } else {
    // 如果不支持Clipboard API，则使用document.execCommand方法
    copyWithDocumentCommand();
  }
};
