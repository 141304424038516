import chat from './chat';
import common from './common';
import create from './create';
import home from './home';
import login from './login';
import personal from './personal';
import pptShare from './pptShare';
export default {
  translation: {
    login,
    common,
    home,
    chat,
    personal,
    create,
    pptShare,
  },
};
