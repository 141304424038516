export default {
  hello: 'hello',
  uploadTitle: 'Chat with document',
  uploadTipsTitle: 'AI Reader',
  uploadTipsDesc: 'Read paper, parse financial statements',
  uploadFiles: 'Upload Files',
  uploading: 'Uploading...',
  uploadFailed: 'Upload failed',
  cardContent: {
    cardTitle: 'AI Presentation',
    cardBoxTitle: 'Generate slides in seconds from a single topic',
    cardBoxTips: 'Available on desktop',
  },
  popTips:
    "Powered by the world's most advanced language model, including ChatGPT",
  tryItOut: 'Explore',
  create: 'Input Topic',
  upload: 'Upload File',
  chatWithDocument: 'Chat with DOC',
  uploadPDFAndLimit: 'Upload PDF/DOC Here',
  aiPresentation: 'AI Presentation',
  enterTitleToGeneratePPT: 'Ideas to Slides',
  creating: 'Creating...',
  creatingFailed: 'Creation failed',
  supportFileType: 'supports {{accept}} type',
};
