export default {
  chatWithPdf: 'Chat With PDF',
  parsingFile: 'Interpreting...',
  someQuestionsAsk: 'Some questions you may ask',
  sendMessage: 'Send a message',
  pages: 'pages',
  regenerate: 'Regenerate Outline',
  generatePPT: 'create Presentation',
  createPresentation: 'Create Presentation',
  errMessageTemplate:
    'Oops, something went wrong. It seems that either the engine you requested does not exist, or there was an issue processing your request.',
  errChat:
    'Oops, something went wrong. It seems that either the engine you requested does not exist, or there was an issue processing your request.',
  generationTime: 'Generation takes approximately 1-2 minutes.',
  viewLater:
    'You can view it later or continue AI editing on the PopAi desktop version.',
  preview: 'Preview',
};
