import chat from './chat';
import common from './common';
import home from './home';
import login from './login';
import personal from './personal';
import create from './create';

export default {
  translation: {
    login,
    common,
    home,
    chat,
    personal,
    create
  },
};
