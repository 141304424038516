import withSuspense from '@/hoc/LazySuspense';
import Layout from '@/layout';
import { lazy } from 'react';
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';

const Home = withSuspense(lazy(() => import('@/pages/Home')));
const Home2 = withSuspense(lazy(() => import('@/pages/Home2')));
const Login = withSuspense(lazy(() => import('@/pages/Login')));
const User = withSuspense(lazy(() => import('@/pages/User')));
const Chat = withSuspense(lazy(() => import('@/pages/Chat')));
const Create = withSuspense(lazy(() => import('@/pages/Create')));
const History = withSuspense(lazy(() => import('@/pages/History')));
const PPTShare = withSuspense(
  lazy(() => import('@/pages/PPTShare/components/ShareView')),
);

const isPop = import.meta.env.VITE_APP_TYPE === 'pzhan';

export const routePrefix = '/m/';

const routers = createBrowserRouter(
  [
    {
      path: '',
      element: isPop ? <Home /> : <Home2 />,
    },
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: 'user',
      element: <User />,
    },
    {
      path: 'history',
      element: <History />,
    },
    {
      path: 'chat/:channelId',
      element: <Chat />,
    },
    {
      path: 'create',
      element: <Create />,
    },
    {
      path: 'ppt/share',
      element: <PPTShare />,
    },
    {
      path: '*',
      element: <Navigate to="/" />,
    },
  ],
  { basename: routePrefix },
);

const Routes = () => {
  return (
    <Layout>
      <RouterProvider router={routers} />
    </Layout>
  );
};

export default Routes;
