export default {
  chatWithPdf: 'الدردشة مع PDF',
  parsingFile: 'جاري تحليل الملف',
  someQuestionsAsk: 'بعض الأسئلة التي قد تسألها',
  sendMessage: 'ارسل رسالة',
  regenerate: '重新生成大纲',
  generatePPT: '生成幻灯片',
  createPresentation: 'Create Presentation',
  errMessageTemplate:
    'عذرا، حدث خطأ. يبدو أن المحرك الذي طلبته غير موجود، أو كان هناك مشكلة في معالجة طلبك.',
  errChat:
    'Oops, something went wrong. It seems that either the engine you requested does not exist, or there was an issue processing your request.',
};
