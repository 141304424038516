/*
 * @Author: zuoyuxing001
 * @Date: 2024-01-02 15:35:58
 * @LastEditors: zuoyuxing001
 * @LastEditTime: 2024-01-05 14:47:34
 * @FilePath: /popai/src/i18n/index.ts
 * @Description:
 */
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import ar from './ar/index.ts';
import en from './en/index.ts';
import zh from './zh/index.ts';

const { VITE_APP_TYPE } = import.meta.env;

i18next
  ?.use(LanguageDetector)
  ?.use(initReactI18next)
  ?.init({
    // lng: window.navigator.language,
    lng: VITE_APP_TYPE === 'pzhan' ? 'en' : 'zh',
    debug: false,
    fallbackLng: 'en',
    resources: {
      en,
      zh,
      ar,
    },
  });

export default i18next;
