import axios from '@/common/request';
import { Channel, DocEntries, ResponseType } from '@/types';
import type { AxiosRequestConfig } from 'axios';

export interface CreateChatParams {
  model: string;
  md5?: string;
  url?: string;
  fileName?: string;
  fileContentType?: string;
  uploadTime?: number;
  /**
   * ocr语言
   */
  languages?: string[] | null;
  /**
   * 解析图片增加的参数，始终传true
   */
  extract_img_5k?: boolean;
}

//创建和获取channel的参数，接口用的都是getChannel，不传channelid就是新建。
export interface GetChannelParams {
  channelId?: string;
  templateId?: string; //从模板创建的时候，模板id
  message?: string; //消息框里问的问题
  model?: string;
  isUpload?: boolean; //是否有文件上传
  filename?: string;
  extname?: string;
  language?: string | null;
  imageUrls?: string[];
  fileType?: number | null;
  /**
   * 使用PDF创建PPT时，PDF的消息ID
   */
  pdfMsgId?: string;
}

export async function getLandingPageInfo<T>() {
  const result = await axios.get<T>('/api/v1/landing');
  return result.data;
}

export async function getConversationList<T>() {
  const result = await axios.get<T>('/api/v1/chat/myChannelList');
  return result.data;
}

export async function editChat<T>(params: {
  channelId: string;
  channelName: string;
}) {
  const result = await axios.post<T>('/api/v1/channel', params);
  return result.data;
}

export async function deleteChat<T>(params: { channelId: string }) {
  const result = await axios.post<T>('/api/v1/channel/delete', params);
  return result.data;
}

export async function createNewChat(
  params: GetChannelParams,
  config?: AxiosRequestConfig,
) {
  const result = await axios.post<ResponseType<Channel>>(
    '/api/v1/chat/getChannel',
    params,
    config,
  );
  return result.data;
}

export async function getStripeProductsUsingGET<T>() {
  const result = await axios.get<T>(`/api/v1/stripe/products`);
  return result.data;
}

export async function getLanguages<T>() {
  const result = await axios.get<T>('/api/v1/landing/locales');
  return result.data;
}

export async function getPresignedUsingGET(params: {
  md5: string;
  bucket?: string;
  prefix?: string;
}) {
  const paramArr = [];
  for (const key in params) {
    paramArr.push(
      `${key}=${
        typeof (params as any)[key] === 'string'
          ? (params as any)[key]
          : JSON.stringify((params as any)[key])
      }`,
    );
  }
  const result = await axios.get<
    ResponseType<{
      fields: object;
      url: string;
    }>
  >(`/py/api/v1/chat/getPresignedPost?${paramArr.join('&')}`, {
    noAlertError: true,
  });
  return result.data;
}

export async function chatCreateUsingPOST(params: CreateChatParams) {
  const result = await axios.post<
    ResponseType<{
      md5: string;
      channel_id: string;
      channel_name: string;
      docEntries: DocEntries;
    }>
  >(`/py/api/v1/chat/create`, params, {
    noAlertError: true,
  });
  return result?.data;
}

export async function cloneChannel(channelId: string) {
  const result = await axios.post<ResponseType<Channel>>(
    `/api/v1/chat/createChannel`,
    { channelId },
  );
  return result.data;
}

interface LandingChannel {
  channel: Channel;
  channelId: string;
  files: { fileName: string; fileType: string }[];
  messageContent?: string;
}

export async function getExampleChannels() {
  const result = await axios.get<ResponseType<LandingChannel[]>>(
    '/api/v1/landing/presetChannelList',
  );
  return result.data;
}

export async function getImages<T>(params: {
  channelId: string;
  beforeImageId?: number;
  limit?: number;
  md5?: string;
  noPayModal?: boolean;
}) {
  const result = await axios.post<T>(
    `/api/v1/chat/getImages`,
    {
      channelId: params.channelId,
      beforeImageId: params?.beforeImageId,
      limit: params?.limit,
      md5: params?.md5,
    },
    { noPayModal: params.noPayModal },
  );
  return result.data;
}

export async function extractImage(params: {
  channelId: string;
  md5?: string;
  startPage: number;
  isMultiDoc?: boolean;
}) {
  const result = await axios.post(`/py/api/v1/chat/extract_image`, params);
  return result.data;
}

export async function getTplPreviewDetail<T>(
  params: { shareKey: string },
  options?: AxiosRequestConfig,
) {
  const paramArr = [];
  for (const key in params) {
    paramArr.push(
      `${key}=${
        typeof (params as any)[key] === 'string'
          ? (params as any)[key]
          : JSON.stringify((params as any)[key])
      }`,
    );
  }
  const result = await axios.get<T>(
    `/api/v1/channel/share/preview?${paramArr.join('&')}`,
    options,
  );
  return result.data;
}

export async function searchTemplateByKeyword<T>(
  params: {
    name: string;
    page?: string;
    pageSize?: string;
  },
  options?: AxiosRequestConfig,
) {
  const result = await axios.post<T>(
    `/api/v1/prompt/recentSearch`,
    params,
    options,
  );
  return result.data;
}

export async function fastSpringOrderUsingPOST<T>(params: any) {
  const result = await axios.post<T>(`/api/v1/fastspring/order`, params);
  return result.data;
}

export async function isFastSpringUsingGET<T>() {
  const result = await axios.get<T>(`/api/v1/membership/isFastspring`);
  return result.data;
}

export async function getFastSpringProductsUsingGET<T>() {
  const result = await axios.get<T>(`/api/v1/fastspring/products`);
  return result.data;
}

// 获取IP和country @晓东
export async function getIpAndCountryUsingGET<T>() {
  const result = await axios.get<T>(`/api/v1/tool/ip`);
  return result.data;
}

export async function marketingActivePOST<T>(params = {}) {
  const result = await axios.post<T>(`/api/v1/marketing/active`, params, {
    noAlertError: true,
  });
  return result.data;
}

export async function isMembershipUnlimited<T>() {
  const result = await axios.get<T>('/api/v1/membership/isUnlimited', {
    noAlertError: true,
  });
  return result.data;
}

// 获取用户注册时间+24小时 与 当前日期的时分秒差
export async function userCountDown<T>() {
  const result = await axios.get<T>('/api/v1/user/countDown', {
    noAlertError: true,
  });
  return result.data;
}

// 获取当前要展示的更新提示文案
export async function getPromptNotes<T>() {
  const result = await axios.get<T>('/api/v1/release/notes');
  return result.data;
}

export async function getExperimentUser<T>(
  params: { eid: string; did?: string },
  options?: AxiosRequestConfig,
) {
  const result = await axios.post<T>(
    `/api/v1/experiment/user`,
    params,
    options,
  );
  return result.data;
}

export async function getExperimentUserState<T>(
  params: { eid: string },
  options?: AxiosRequestConfig,
) {
  const result = await axios.post<T>(
    `/api/v1/experiment/user/state/get`,
    params,
    options,
  );
  return result.data;
}

export async function saveExperimentUserState<T>(
  params: { eid: string; state: string },
  options?: AxiosRequestConfig,
) {
  const result = await axios.post<T>(
    `/api/v1/experiment/user/state/save`,
    params,
    options,
  );
  return result.data;
}

export async function isStripeEmbedGET<T>() {
  const result = await axios.get<T>('/api/v1/membership/isStripeEmbed', {
    noAlertError: true,
  });
  return result.data;
}

export async function billingDetailGET<T>() {
  const result = await axios.get<T>('/api/v1/billing/detail');
  return result.data;
}

export async function cancelSubscriptionPOST<T>(data: {
  feedback: string;
  subscriptionId: string;
}) {
  const result = await axios.post<T>(
    '/api/v1/billing/subscription/cancel',
    data,
  );
  return result.data;
}

export async function getPayment<T>() {
  const result = await axios.get<T>('/api/v1/membership/payment', {
    noAlertError: true,
  });
  return result.data;
}

export async function getAllProductsUsingGET<T>() {
  const result = await axios.get<T>(`/api/v1/membership/allProducts`, {
    useRiskControl: true,
  });
  return result.data;
}

// 创建partnerShare账号
export async function createPartnerShareAccount<T>() {
  const result = await axios.get<T>('/api/v1/partner/create', {
    useRiskControl: true,
  });
  return result.data;
}

// login 返回跳转链接
export async function getPartnerShareLink<T>() {
  const result = await axios.get<T>('/api/v1/partner/login', {
    useRiskControl: true,
  });
  return result.data;
}

export async function getIsPartner<T>() {
  const result = await axios.get<T>('/api/v1/user/isPartner');
  return result.data;
}

// 获取历史记录
export async function getHistoryChannelList<T>(params: {
  page: string | number;
  pageSize: string | number;
}) {
  const paramArr = [];
  for (const key in params) {
    paramArr.push(`${key}=${params[key as keyof typeof params]}`);
  }

  const result = await axios.get<T>(
    `/api/v1/channel/historyChannelList?${paramArr.join('&')}`,
  );
  return result.data;
}
