import { routePrefix } from '@/router';
import { defaultUserInfo, useEventStore, useUserStore } from '@/store';
import { Toast } from 'antd-mobile';
import a from 'axios';
import { nanoid } from 'nanoid';
import { useEffect, useState } from 'react';
import { getRiskControlToken } from './utils';

const { VITE_APP_APP_NAME, VITE_APP_BASE_URL } = import.meta.env;

const axios = a.create({
  baseURL: VITE_APP_BASE_URL,
  withCredentials: true,
});

declare module 'axios' {
  export interface AxiosRequestConfig {
    noAuthHeader?: boolean;
    noAlertError?: boolean; // 请求错误是否全局弹窗
    noPayModal?: boolean; // 是否显示付费弹窗
    useRiskControl?: boolean; // 请求是否接入风控
    throwError?: boolean; // 是否抛出错误
  }
}

export const TOKEN_KEY = 'webapp-authorization-storage';

export enum RESPONSE_DATA_CODE {
  Success = 200,
  UsageExceedsLimit = 20001,
  FilePagesExceedsLimit = 20002,
  ToBeSubmit = 20010,
  InReview = 20011,
  URL_PARSING_ERROR = 1005,
  FILE_TYPE_UNSUPPORT = 1002,
  InterfaceForbidden = 40001,
  StopChannel = 30011,
  RISK_ANALYZE_INTERCEPT = 20020, // 风控识别为高风险操作
  FILE_CONTENT_RISK_ERROR = 1009,
  TEENAGER_PROTECT = 20030,
}

const AxiosInterceptor = ({ children }: { children: any }) => {
  const { setUserInfo } = useUserStore();
  const { webId, setWebId } = useEventStore.getState();
  const [isSet, setIsSet] = useState(false);

  useEffect(() => {
    setIsSet(true);

    const interceptorRequest: number = axios.interceptors.request.use(
      async (config) => {
        config.headers.Accept = 'application/json';
        config.headers['X-Requested-By'] = 'H5';
        const token = localStorage.getItem(TOKEN_KEY);
        if (token && !config?.noAuthHeader) {
          config.headers.Authorization = token;
        }

        if (
          config?.useRiskControl &&
          import.meta.env.VITE_APP_TYPE === 'pzhan'
        ) {
          let jt = await getRiskControlToken();
          config.headers['x-popai-riskControlToken'] = jt;
        }

        config.headers['App-Name'] = VITE_APP_APP_NAME;
        let localWebId = webId;
        if (!localWebId) {
          const newWebId = nanoid();
          localWebId = newWebId;
          setWebId(newWebId);
        }
        config.headers['Device-Info'] = JSON.stringify({
          web_id: localWebId,
          baidu_id: localStorage.getItem('__bid_n'),
        });

        return config;
      },
    );

    const interceptorResponse: number = axios.interceptors.response.use(
      async (res) => {
        if (res.config.noAuthHeader && res.status >= 200 && res.status < 300) {
          return res;
        }
        const { data } = res;
        if (+data.code === RESPONSE_DATA_CODE.Success) {
          return res;
        }
        throw res;
      },
      (err) => {
        const noAlertError = err?.config?.noAlertError;
        const noPayModal = err?.config?.noPayModal;
        const throwError = err?.config?.throwError;
        if (
          err?.response?.data?.code === RESPONSE_DATA_CODE.InterfaceForbidden
        ) {
          window.location.href = '/403.html';
          return;
        }
        if (err?.response?.status === 401) {
          localStorage.removeItem(TOKEN_KEY);
          setUserInfo(defaultUserInfo);
          window.location.href = routePrefix + '/login';
        }

        if (err?.response?.data?.code === RESPONSE_DATA_CODE.TEENAGER_PROTECT) {
          // 万知青少年模式，暂未翻译
          Toast.show('已超过今日可使用时间，请明日再次登录');
          localStorage.removeItem(TOKEN_KEY);
          window.location.href = routePrefix;
          return;
        }
        if (
          err?.response?.data?.code === RESPONSE_DATA_CODE.UsageExceedsLimit
        ) {
          if (!noPayModal) {
            Toast.show(
              `${err?.response?.data?.message}. To proceed using a desktop browser.`,
            );
            return;
          }
          if (throwError) {
            throw new Error(
              `${err?.response?.data?.message}. To proceed using a desktop browser.`,
            );
          }
        }
        if (!noAlertError) {
          const errMsg = err?.response?.data?.message || err.message;
          Toast.show(errMsg);
        }
        throw err;
      },
    );

    return () => {
      axios.interceptors.request.eject(interceptorRequest);
      axios.interceptors.response.eject(interceptorResponse);
    };
  }, []);
  return isSet && children;
};

export default axios;
export { AxiosInterceptor };
