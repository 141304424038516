export default {
  feedback:'反馈',
  teamsOfUse:'用户协议',
  privacyPolicy:'隐私协议',
  rateUs:'评分',
  logOut:'退出',
  cancel:'取消',
  modalTitle:'你是否确认要退出账号',
  usage: {
    manageBilling: '管理账单',
    enjoyInfinite: '享受无限对话',
    per: '每',
    month: '月',
    day: '天',
    Usage: '用法',
    or: '或',
    unlimitedPlan: '无限计划',
    upgradeToPro: '升级到专业版',
  },
}