import { Result } from 'antd-mobile';
import { Component, ErrorInfo, ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

export default class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: ErrorInfo): void {
    console.log('%c Line:30 🥓 info', 'color:#f5ce50', info.componentStack);
    if (
      error?.message?.includes('Failed to fetch dynamically imported module') ||
      error?.message?.includes('Unable to preload CSS for')
    ) {
      location.reload();
    }
    throw error;
  }

  render(): ReactNode {
    if (this.state.hasError) {
      return (
        <Result
          status="error"
          title="error"
          description="Sorry, something went wrong."
        />
      );
    }

    return this.props.children;
  }
}
