export enum MEMBERSHIP_ID {
  Free = 0,
  MonthPro = 1,
  // 试用会员
  Trail = 2,
  YearPro = 3,
  MonthUnlimited = 4,
  YearUnlimited = 5,
  MonthProPlus = 6,
  YearProPlus = 7,
}

export const FAKE_MSG_PROMPT_ID = 'fake-message-prompt-id';

export const FAKE_MSG_ANSWER_ID = 'fake-message-answer-id';

export enum TPL_TYPE {
  Mine = 'Mine',
  Recommended = 'Recommended',
  All = 'All',
  MyProject = 'MyProject',
}

export const darkThemes = ['dark', 'blue', 'green', 'black', 'business'];

export const DOC_FILE_MIME_WHITE_LIST = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

export const CUSTOM_RESPONSE_HEADER = 'yj-x-content';
